<template>
  <div>
    <b-card>
      <b-form-group
        class="font-weight-semibold mb-0"
        label="Category">
        <b-row class="align-items-end mt-1">
          <b-col
            sm="12"
            md="4">
            <SelectField
              v-model="categoryLevel.categorySearchLvlOne"
              :disabled="!lvlOneOptions || lvlOneOptions.length <= 0"
              name="Category level 1"
              form-label="Category level 1"
              placeholder="Category level 1"
              return-value="id"
              label-option="name"
              :options="lvlOneOptions"
              @input="selectedItem($event, 1)"
            />
          </b-col>
          <b-col
            sm="12"
            md="4">
            <SelectField
              v-model="categoryLevel.categorySearchLvlTwo"
              :disabled="!lvlTwoOptions || lvlTwoOptions.length <= 0"
              name="Category level 2"
              form-label="Category level 2"
              placeholder="Category level 2"
              :options="lvlTwoOptions"
              return-value="id"
              label-option="name"
              @input="selectedItem($event, 2)"
            />
          </b-col>
          <b-col
            sm="12"
            md="4">
            <SelectField
              v-model="categoryLevel.categorySearchLvlThree"
              :disabled="!lvlThreeOptions || lvlThreeOptions.length <= 0"
              name="Category level 3"
              form-label="Category level 3"
              placeholder="Category level 3"
              :options="lvlThreeOptions"
              return-value="id"
              label-option="name"
              @input="selectedItem($event, 3)"
            />
          </b-col>
          <b-col
            sm="12"
            md="4">
            <SelectField
              v-model="categoryLevel.categorySearchLvlFour"
              :disabled="!lvlFourOptions || lvlFourOptions.length <= 0"
              name="Category level 4"
              form-label="Category level 4"
              placeholder="Category level 4"
              :options="lvlFourOptions"
              return-value="id"
              label-option="name"
              @input="selectedItem($event, 4)"
            />
          </b-col>
          <b-col
            sm="12"
            md="4">
            <SelectField
              v-model="categoryLevel.categorySearchLvlFive"
              :disabled="!lvlFiveOptions || lvlFiveOptions.length <= 0"
              name="Category level 5"
              form-label="Category level 5"
              placeholder="Category level 5"
              :options="lvlFiveOptions"
              return-value="id"
              label-option="name"
              @input="selectedItem($event, 5)"
            />
          </b-col>
          <b-col
            sm="12"
            md="4">
            <SelectField
              v-model="categoryLevel.categorySearchLvlSix"
              :disabled="!lvlSixOptions || lvlSixOptions.length <= 0"
              name="Category level 6"
              form-label="Category level 6"
              placeholder="Category level 6"
              :options="lvlSixOptions"
              return-value="id"
              label-option="name"
              @input="selectedItem($event, 6)"
            />
          </b-col>
        </b-row>
      </b-form-group>
      <div class="my-2">
        <SearchTextInput
          v-model="filter.searchCodeAndSubject"
          label="Code, Subject, Problem Detail, Solution Detail"
          placeholder="Search"
          @keyup.enter.native="fetchSearch()" />
      </div>
      <b-row>
        <b-col
          cols="6"
        >
          <SelectField
            v-model="filter.searchType"
            :options="typeOptions"
            name="Type"
            multiple
            placeholder="Search Type"
            :close-on-select="false"
            :deselect-from-dropdown="true"
            form-label="Type"
            label-option="title"
            return-value="id" />
        </b-col>
        <b-col cols="6">
          <TagsField
            v-model="filter.tags"
            label="Tags"
            placeholder="Search Tags"
          />
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="my-1"
          @click="fetchSearch()"
        >
          <feather-icon icon="SearchIcon" />Search
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import SelectField from '@/components/Form/SelectField.vue'
import CategoriesProvider from '@/resources/CategoriesProvider'
import KnowledgeTypeProvider from '@/resources/KnowledgeTypeProvider'
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import { mapGetters } from 'vuex'
import TagsField from '@/components/Form/TagsField.vue'

const CategoriesService = new CategoriesProvider()
const KnowledgeTypeService = new KnowledgeTypeProvider()

export default {
  components: {
    SelectField,
    SearchTextInput,
    TagsField
    // KnowledgeFilter,
    // KnowledgeSelectedSideBarVue
  },
  data () {
    return {
      perPageOptions: [10, 20, 50, 100, 500, 1000],
      isKnowledgeSelectedSideBarActive: false,
      selected: [],
      deleted: [],
      selectedAvalibelSave: [],
      refreshIsInCategory: true,
      typeOptions: [],
      filter: {
        searchCodeAndSubject: '',
        searchType: [],
        tags: []
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'selected', label: '' },
        { key: 'code', label: 'Code', sortable: false },
        { key: 'subject',
          label: ' Subject',
          thStyle: {
            width: '20rem'
          } },
        { key: 'problemDetail', label: ' Problem' },
        { key: 'solutionDetail', label: ' Solution' },
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
        { key: 'isInCategory', label: '' }
      ],
      items: [
      ],
      knowledge: {
        code: '',
        subject: '',
        problemDetail: '',
        solutionDetail: '',
        problemFile: '',
        solutionFile: '',
        categoryId: ''
      },
      mockData: [
        { label: '#001', value: 0 },
        { label: '#002', value: 1 },
        { label: '#003', value: 2 },
        { label: '#004', value: 3 },
        { label: '#005', value: 4 }
      ],
      files: {
        problemFile: [],
        solutionFile: []
      },
      lvlOneOptions: [],
      lvlTwoOptions: [],
      lvlThreeOptions: [],
      lvlFourOptions: [],
      lvlFiveOptions: [],
      lvlSixOptions: [],
      categories: [],
      categoryLevel: {
        categorySearchLvlOne: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      },
      options: [
        { text: 'Orange', value: 'orange' },
        { text: 'Apple', value: 'apple' },
        { text: 'Pineapple', value: 'pineapple' },
        { text: 'Grape', value: 'grape' }
      ]
    }
  },
  computed: {
    currentCategoryId () {
      return this.getLastItem(this.categoryLevel)
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    })
  },
  async mounted () {
    await this.getAllCategories()
    await this.getKnowledgeType()
  },
  methods: {
    async getAllCategories () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await CategoriesService.findAll()
        this.categories = data
        this.setOptionLvlOne(data)
        // this.lvlOneOptions = data.filter((item) => item.level === 1)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getKnowledgeType () {
      try {
        const { data } = await KnowledgeTypeService.paginate(1, 9999999)
        this.typeOptions = data
      } catch (error) {
        console.error(error)
      }
    },
    fetchSearch () {
      const payload = {
        searchCodeAndSubject: this.filter.searchCodeAndSubject,
        searchType: this.filter.searchType,
        tags: this.filter.tags,
        categoriesIds: [this.currentCategoryId]
      }
      if (!this.currentCategoryId) {
        delete payload.categoriesIds
      }

      if (!this.filter?.searchCodeAndSubject) {
        delete payload.searchCodeAndSubject
      }
      this.$emit('fetch-filter', payload)
    },
    setOptionLvlOne (val) {
      if (val) {
        this.lvlOneOptions = val.filter((item) => item?.level === 1 && item?.visibelGroup?.includes(this.profile?.userGroupId))
      }
    },
    selectedItem (parentId, level) {
      const filter = this.findCategory(parentId)
      switch (level) {
      case 1:
        this.lvlTwoOptions = filter
        this.lvlThreeOptions = []
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlTwo = null
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 2:
        this.lvlThreeOptions = filter
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 3:
        this.lvlFourOptions = filter
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 4:
        this.lvlFiveOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 5:
        this.lvlSixOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlSix = null
        break
      default:
        break
      }
    },
    findCategory (parentId) {
      return this.categories.filter((item) => item.parentId === parentId)
    },
    getLastItem (obj) {
      const convertToArray = Object.keys(obj).map((key) => obj[key])
      let result = null
      convertToArray.forEach((item) => {
        if (item && item !== '') {
          result = item
        }
      })
      return result
    }
  }
}
</script>
<style scoped>
.clamped-text {
  width: 30px;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
</style>
